import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Paged, params} from "../model/common";
import {Locale, LocalisationEditModel, Localization, LocalizationCriteria} from "../model/localization";
import {AuthService} from "./auth.service";

const API = '/api/localization';

@Injectable()
export class LocalizationsService {
    criteria: LocalizationCriteria;
    showKeys: boolean;
    lastViewedId: number;
    lastViewedPosition: number;

    constructor(private http:HttpClient, private authService: AuthService) {
        this.authService.onLogout.subscribe(() => {
            this.criteria = null;
            this.showKeys = false;
            this.lastViewedPosition = null;
            this.lastViewedId = null;
        });
    }

    page(criteria: LocalizationCriteria, includeMissingValues:boolean = false): Observable<Paged<Localization>> {
        let p:HttpParams = params(criteria);
        if (includeMissingValues) {
            p = p.append('includeMissingValues', 'true');
        }
        return this.http.get<Paged<Localization>>(`${API}/page`, {params: p});
    }

    get(id: number): Observable<LocalisationEditModel> {
        return this.http.get<LocalisationEditModel>(`${API}/byId/${id}`);
    }

    update(localization: LocalisationEditModel): Observable<any> {
        return this.http.put(`${API}/byId/${localization.id}`, localization);
    }

    listLocales(): Observable<Locale[]> {
        return this.http.get<Locale[]>(`${API}/locale`);
    }
}