import {Component, OnDestroy, OnInit} from '@angular/core';
import {Application} from "../../core/model/application";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {ApplicationService} from "../../core/service/application.service";
import {ToastService} from "../../core/service/toast.service";
import {I18nService} from "../../core/service/i18n.service";

@Component({
  selector: 'app-edit-application',
  templateUrl: './edit-application.component.html'
})
export class EditApplicationComponent implements OnInit, OnDestroy {
  application: Application;
  private paramsSubscription: any;

  constructor(private route: ActivatedRoute,
              private applicationService: ApplicationService,
              private i18nService: I18nService,
              private toastService: ToastService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.paramsSubscription = this.route.params.subscribe((params: Params) => {
      this.applicationService.get(params['identifier']).subscribe(application => {
        this.application = application;
        this.application.locales.sort((a, b) => {
          return a.orderNumber - b.orderNumber;
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  save() {
    this.application.locales.forEach(item => {
      item.orderNumber = this.application.locales.indexOf(item);
    })
    this.applicationService.update(this.application).subscribe(() => {
      this.toastService.message(this.i18nService.getLocalizedValue('application.update.success',
        this.application.name));
      this.router.navigate(['/applications']);
    });
  }
}
