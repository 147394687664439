import {Pipe, PipeTransform} from "@angular/core";
import {AuthService} from "../../core/service/auth.service";

@Pipe({name: 'hasRole'})
export class HasRolePipe implements PipeTransform {
    constructor(private authService: AuthService) {
    }

    transform(value: string|string[], ...args: any[]): any {
        if (!(value instanceof Array)) {
            value = [value];
        }
        return this.authService.hasAnyRole(value);
    }
}