import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {LocalizationsComponent} from "./localizations.component";
import {AuthGuard} from "../core/guard/auth-guard.service";
import {ListLocalizationsComponent} from "./list-localizations/list-localizations.component";
import {EditLocalizationComponent} from "./edit-localization/edit-localization.component";


const routes: Routes = [
    {
        path: 'localizations',
        component: LocalizationsComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                canActivateChild: [AuthGuard],
                children: [
                    {path: '', component: ListLocalizationsComponent, pathMatch: 'full'},
                    {path: 'edit/:id', component: EditLocalizationComponent}
                ]
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class LocalizationsRoutingModule {
}
