import {Component, ContentChild, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import * as jQuery from 'jquery';
import * as moment from 'moment';
import {Moment} from 'moment';

import { Directive } from '@angular/core';

@Directive({
    selector: '[sideNaviItem]'
})
export class SideNaviItemDirective {}

@Directive({
    selector: '[sideNaviItem]'
})
export class SideNaviContentDirective {}


export interface NaviItem {
    route: string;
    i18n?: string;
    text?: string;
    data?: any;
}

@Component({
    selector: 'side-navi',
    templateUrl: './side-navi.component.html',
})
export class SideNaviComponent {

    @ContentChild(SideNaviItemDirective, {read: TemplateRef})
    sideNaviItemTemplate;

    @Input()
    items: NaviItem[];
}
