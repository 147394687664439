import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Role} from '../model/role';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class RoleService {
    apiUrl = '/api/user/role';

    constructor (private http: HttpClient) { }

    listRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(this.apiUrl);
    }
}
