import {NgModule} from "@angular/core";
import {SharedModule} from "../shared/shared.module";
import {UserModule} from "../admin/user/user.module";
import {LocalizationsComponent} from "./localizations.component";
import {LocalizationsRoutingModule} from "./localizations-routing.module";
import {EditLocalizationComponent} from './edit-localization/edit-localization.component';
import {ListLocalizationsComponent} from './list-localizations/list-localizations.component';

@NgModule({
    imports: [
        SharedModule,
        UserModule,
        LocalizationsRoutingModule
    ],
    declarations: [
        LocalizationsComponent,
        EditLocalizationComponent,
        ListLocalizationsComponent
    ]
})
export class LocalizationsModule {
}
