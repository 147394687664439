import {
    Directive,
    TemplateRef,
    ViewContainerRef,
    OnInit,
    DoCheck
} from '@angular/core';
import {AuthService} from '../../core/service/auth.service';


abstract class IsAnonOrAuthDirective implements OnInit, DoCheck {

    private hasView = false;

    constructor(private authService: AuthService,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    protected abstract needsToBeAuthenticated(): boolean;


    ngOnInit(): void {
        this.showOrHideContent();
    }

    ngDoCheck(): void {
        this.showOrHideContent();
    }


    protected shouldBeIncluded(): boolean {
        return (this.needsToBeAuthenticated() && this.authService.isLoggedIn()) ||
            (!this.needsToBeAuthenticated() && !this.authService.isLoggedIn());
    }

    protected showOrHideContent(): void {
        if (this.shouldBeIncluded() && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!this.shouldBeIncluded() && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }
}


@Directive({selector: '[isAuthenticated]'})
export class AuthenticatedDirective extends IsAnonOrAuthDirective implements OnInit, DoCheck {
    constructor(authService: AuthService,
                templateRef: TemplateRef<any>,
                viewContainer: ViewContainerRef) {
        super(authService, templateRef, viewContainer);
    }

    protected needsToBeAuthenticated(): boolean {
        return true;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngDoCheck(): void {
        super.ngDoCheck();
    }
}

@Directive({selector: '[isAnonymous]'})
export class AnonymousDirective extends IsAnonOrAuthDirective implements OnInit, DoCheck {
    constructor(authService: AuthService,
                templateRef: TemplateRef<any>,
                viewContainer: ViewContainerRef) {
        super(authService, templateRef, viewContainer);
    }

    protected needsToBeAuthenticated(): boolean {
        return false;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngDoCheck(): void {
        super.ngDoCheck();
    }
}
