import {EventEmitter, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

export interface GlobalError {
    message: string;
}

export interface FieldError {
    field: string;
    message: string;
}

@Injectable()
export class ErrorHandlerService {

    private _doGlobalErrorHandling = true;

    globalError: EventEmitter<GlobalError> = new EventEmitter();
    fieldError: EventEmitter<FieldError> = new EventEmitter();
    clearFieldErrors: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    handleError(errorResponse: HttpErrorResponse): void {
        const error: any = errorResponse.error;

        if (error.violations) {
            this.handleValidationError(error);
        } else {
            console.info('emit global error', error);
            this.globalError.emit({
                message: error.message || errorResponse.message
            });
        }
    }

    clearErrors(): void {
        this.clearFieldErrors.emit(null);
    }


    private handleValidationError(error): void {
        this.clearErrors();
        error.violations.forEach(e => {
            // e[1].forEach(e2 =>
            this.fieldError.emit({
                field: e.field,
                message: e.message
            });
            // );
        });
        this.globalError.emit({message: error.message});
    }

    // private handleLocalizableError(error): void {
    //     let message = this.i18nService.getLocalizedValue(error.messageKey, ...(error.params || []));
    //     this.globalError.emit({ message });
    // }


    get doGlobalErrorHandling(): boolean {
        return this._doGlobalErrorHandling;
    }

    public withGlobalErrorHandlingOn(cb: () => void): void {
        this.withGlobalErrorHandling(true, cb);
    }

    public withGlobalErrorHandlingOff(cb: () => void): void {
        this.withGlobalErrorHandling(false, cb);
    }

    public withGlobalErrorHandling(errorHandlingOn: boolean, cb: () => void): void {
        const old = this.doGlobalErrorHandling;
        this._doGlobalErrorHandling = errorHandlingOn;
        cb();
        this._doGlobalErrorHandling = old;
    }
}
