import {Component, OnInit} from '@angular/core';
import {User, UserList} from '../../core/model/user';
import {UserService} from '../../core/service/user.service';
import {I18nService} from "../../core/service/i18n.service";
import {AuthService} from "../../core/service/auth.service";

@Component({
    templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {
    users: UserList[] = [];
    currentUser: User;

    constructor(private userService: UserService,
                private i18nService: I18nService,
                private authService: AuthService) { }

    ngOnInit(): void {
        this.listUsers();
        this.authService.getLoggedInUser().subscribe(user => {
            this.currentUser = user;
        });
    }

    listUsers(): void {
        this.userService.listUsers()
            .subscribe(users => this.users = users);
    }

    deleteUser(user: User): void {
        if (confirm(this.i18nService.getLocalizedValue('admin.user.confirmDelete', user.username))) {
            this.userService.deleteUser(user.id)
                .subscribe(() => this.listUsers());
        }
    }
}
