import {NgModule} from "@angular/core";
import {SharedModule} from "../shared/shared.module";
import {UserModule} from "../admin/user/user.module";
import {ApplicationsRoutingModule} from "./applications-routing.module";
import {ApplicationsComponent} from "./applications.component";
import { CreateApplicationComponent } from './create-application/create-application.component';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { EditApplicationComponent } from './edit-application/edit-application.component';
import { ApplicationListComponent } from './application-list/application-list.component';
import {SortablejsModule} from "angular-sortablejs";


@NgModule({
  imports: [
    SharedModule,
    UserModule,
    ApplicationsRoutingModule,
    SortablejsModule
  ],
    declarations: [
        ApplicationsComponent,
        CreateApplicationComponent,
        ApplicationFormComponent,
        EditApplicationComponent,
        ApplicationListComponent
    ]
})
export class ApplicationsModule {
}
