import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nService} from "./service/i18n.service";
import {HttpClientModule} from "@angular/common/http";
import {AuthService} from "./service/auth.service";
import {UserService} from "./service/user.service";
import {ErrorHandlerService} from "./service/error-handler.service";
import {RoleService} from "./service/role.service";
import {AdminRoleGuard, UserRoleGuard} from "./guard/role-guard.service";
import {AuthGuard} from "./guard/auth-guard.service";
import {ErrorHandlerInterceptor} from "./interceptor/error-handler.interceptor";
import {ProfileService} from "./service/profile.service";
import {ToastService} from "./service/toast.service";
import {LocalizationsService} from "./service/localizations.service";
import {ApplicationService} from "./service/application.service";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        I18nService,
        AuthService,
        UserService,
        ProfileService,
        RoleService,
        ToastService,
        LocalizationsService,
        ApplicationService,

        ErrorHandlerService,
        ErrorHandlerInterceptor,

        AuthGuard,
        AdminRoleGuard,
        UserRoleGuard
    ]
})
export class CoreModule {
}
