import {HttpParams} from "@angular/common/http";

export interface BasicCriteria extends BaseCriteria {
    id?: number|number[]
    ids?: number[]
}

export type Direction = 'asc' | 'desc';

export interface BaseCriteria {
    // pagination by bage and size
    page?: number // current page
    size?: number // page size
    // or by offset and limit:
    offset?: number
    limit?: number
    // ordering by orders or combined orderBy string or values
    order?: Order[] // use with JSON format only
    orderBy?: string|string[]
    direction?: Direction|Direction[]

    select?: string
    include?: string
    exclude?: string
}

export interface Order {
    by: string // field
    direction?: string // asc/desc
}

export interface Paged<T> {
    totalResultCount: number
    results: T[]
}

export function params(params) {
    let searchParams = new HttpParams();
    if (params) {
        Object.keys(params).forEach(key => {
            const value = params[key];
            if (value === 'null' || value === null || value === undefined) {
                return;
            }
            if (value && Array.isArray(value)) {
                value.forEach(element => searchParams = searchParams.append(key, element.toString()));
            } else {
                searchParams = searchParams.append(key, value.toString());
            }
        });
    }
    return searchParams;
}