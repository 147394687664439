import {Component, EventEmitter, Input, Output} from "@angular/core";
import {BaseCriteria} from "../../../core/model/common";


@Component({
    selector: 'order-by-column',
    template: `
        <div class="row">
            <div class="col-xs-8 col-8">
                <ng-content></ng-content>
            </div>
            <div class="col-xs-4 col-4">
                <span class="fa clickable" (click)="toggle()" [ngClass]="{'undelrined': isOrderedBy(), 'fa-chevron-up': !isOrderedBy() || isAsc(), 'fa-chevron-down': isOrderedBy() && !isAsc()}"> 
                </span>
            </div>
        </div>
    `,
    styles: [`
        .row {
            min-width: 10em;
        }
        .clickable {
            cursor: pointer;
        }
        .undelrined {
            text-decoration: underline;
        }
    `]
})
export class OrderByColumnComponent {
    @Input() criteria: BaseCriteria;
    @Input() field: string;
    @Output() change: EventEmitter<BaseCriteria> = new EventEmitter<BaseCriteria>();

    toggle() {
        const was = this.criteria.orderBy;
        this.criteria.orderBy = this.field;
        if (was === this.field) {
            if (this.criteria.direction instanceof Array) {
                this.criteria.direction = null;
            }
            this.criteria.direction = !this.criteria.direction || this.criteria.direction == 'desc' ? 'asc' : 'desc';
        } else {
            this.criteria.direction = 'asc';
        }
        this.change.emit(this.criteria);
    }

    isOrderedBy() {
        return this.criteria.orderBy === this.field;
    }

    isAsc() {
        if (this.criteria.direction instanceof Array) {
            return true;
        }
        return !this.criteria.direction || this.criteria.direction === 'asc';
    }
}