import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorHandlerService} from '../service/error-handler.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(private errorHandlerService: ErrorHandlerService) {
        //console.log('ErrorHandlerInterceptor created');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log('ErrorHandlerInterceptor.intercept');

        if (this.isModification(request)) {
            //console.log('modification: clear errors');
            this.errorHandlerService.clearErrors();
        }

        if (!this.errorHandlerService.doGlobalErrorHandling) {
            return next.handle(request);
        }

        return next.handle(request).pipe(catchError(response => {
            if (response instanceof HttpErrorResponse) {
                if (response.status === 401) {
                    if (request != null && request.url && request.url.indexOf('/api/auth/login') !== -1) {
                        return throwError(response);
                    }
                    window.location.href = 'login';
                } else {
                    this.errorHandlerService.handleError(response);
                }
            }
            return throwError(response);
        }));
    }

    private isModification(request: HttpRequest<any>) {
        return ['POST', 'PUT'].includes(request.method.toUpperCase());
    }
}
