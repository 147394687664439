import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../core/service/auth.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    username: string;
    password: string;
    failed: boolean;

    constructor(private authService: AuthService, private router: Router) {
    }

    onSubmit(): void {
        this.failed = false;
        this.authService.login(this.username, this.password)
            .subscribe(() => {
                if (this.authService.isLoggedIn()) {
                    // Get the redirect URL from our auth service
                    // If no redirect has been set, use the default
                    const redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';
                    // Redirect the user
                    this.router.navigate([redirect]);
                }
            },() => {
                this.failed = true;
            });
    }
}
