import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Application, ApplicationCriteria, ApplicationLocaleList} from "../model/application";
import {Observable} from "rxjs";
import {params} from "../model/common";

const API = '/api/application';

export interface LocaleParams {
    supported?: boolean
    withAccess?: boolean
}

@Injectable()
export class ApplicationService {
    constructor(private http:HttpClient) {
    }

    list(criteria: ApplicationCriteria): Observable<Application[]> {
        return this.http.get<Application[]>(API, {params: params(criteria)});
    }

    get(identifier: string): Observable<Application> {
        return this.http.get<Application>(`${API}/${identifier}`);
    }

    create(application: Application): Observable<number> {
        return this.http.post<number>(API, application);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${API}/${id}`);
    }

    update(application: Application) {
        return this.http.put<number>(`${API}/${application.id}`, application);
    }

    listLocales(applicationIdentifier: string, p?: LocaleParams) {
        return this.http.get<ApplicationLocaleList[]>(`${API}/${applicationIdentifier}/locale`,
            {params: params(p)});
    }

    languageReadyForApproval(applicationId: number, locale: string) {
        return this.http.post(`${API}/${applicationId}/locale/${locale}/readyForApproval`, null);
    }
}
