import {Directive, ElementRef, Input, OnInit, OnDestroy} from '@angular/core';
import {ErrorHandlerService, FieldError} from '../../core/service/error-handler.service';

import * as jQuery from 'jquery';

const invalidClass = 'is-invalid';
const containerClass = 'form-group';
const validationMessageClass = 'invalid-feedback';

@Directive({
    selector: '[fieldError]'
})
export class FieldErrorDirective implements OnInit, OnDestroy {

    @Input() fieldError = '';
    @Input() inputId: string;
    @Input() active = true;

    constructor(private el: ElementRef,
                private errorProvider: ErrorHandlerService) { }


    public ngOnInit() {
        this.errorProvider.fieldError.subscribe((error: FieldError) => {
            if (this.getFullPath() === error.field) {
                console.log(this.getFullPath(), '==', error.field, error);
                this.addError(error.message);
            }
        });

        this.errorProvider.clearFieldErrors.subscribe(() => {
            this.clear();
        });
    }

    public ngOnDestroy() {
        this.clear();
    }

    private getFieldName(): string {
        const $el = jQuery(this.el.nativeElement);
        return this.fieldError || $el.attr('name') || $el.attr('id');
    }

    private getFullPath() {
        const $el = jQuery(this.el.nativeElement);
        let path = this.getFieldName();

        $el.parents('[fieldErrorContext]').each((i, el) => {
            path = jQuery(el).attr('fieldErrorContext') + '.' + path;
        });

        return path;
    }

    private addError(message: string): void {
        jQuery(this.el.nativeElement).addClass(invalidClass);
        this.getContainer()
            .append('<p class="' + validationMessageClass + '">' + message + '</p>');
    }

    private clear() {
        jQuery(this.el.nativeElement).removeClass(invalidClass);
        this.getContainer()
            .find('.' + validationMessageClass)
            .remove();
    }

    private getContainer() {
        return jQuery(this.el.nativeElement).closest('.' + containerClass);
    }
}
