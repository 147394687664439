import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from "./core/service/auth.service";
import {Router} from "@angular/router";
import {ErrorHandlerService, GlobalError} from "./core/service/error-handler.service";
import {ToastService} from "./core/service/toast.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    currentYear = new Date().getFullYear();

    constructor(private authService: AuthService,
                private router: Router,
                private errorHandlerService: ErrorHandlerService,
                private toastService:ToastService) {
    }

    ngOnInit(): void {
        this.errorHandlerService.globalError.subscribe((error: GlobalError) => {
            this.toastService.message(error.message, 'danger');
        });
    }

    get username(): string {
        return this.authService.loggedInUser.username;
    }

    logout() {
        this.authService.logout().subscribe(() => {
            this.router.navigate(['/login']);
        });
    }
}
