import {Component} from '@angular/core';
import {ChangePassword} from '../core/model/profile';
import {ProfileService} from '../core/service/profile.service';
import {ToastService} from "../core/service/toast.service";
import {I18nService} from "../core/service/i18n.service";

@Component({
    templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent {

    pwChange: ChangePassword = {
        currentPassword: '',
        newPassword: '',
        newPasswordAgain: ''
    };

    constructor(private profileService: ProfileService,
                private toastService: ToastService,
                private i18nService: I18nService) {
    }

    onSubmit(): void {
        this.profileService.changePassword(this.pwChange)
            .subscribe(() => this.toastService.message(
                this.i18nService.getLocalizedValue('changePassword.success'), 'success'));
    }
}

