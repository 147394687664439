import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';

import {ProfileRoutingModule} from './profile-routing.module';
import {ProfileComponent} from './profile.component';
import {EditProfileComponent} from './edit-profile.component';
import {ChangePasswordComponent} from './change-password.component';


@NgModule({
    imports: [
        SharedModule,
        ProfileRoutingModule
    ],
    declarations: [
        ProfileComponent,
        EditProfileComponent,
        ChangePasswordComponent
    ]
})
export class ProfileModule {
}
