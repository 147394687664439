import {Component, OnInit} from '@angular/core';
import {Profile} from '../core/model/profile';
import {ProfileService} from '../core/service/profile.service';
import {ToastService} from "../core/service/toast.service";

@Component({
    templateUrl: './edit-profile.component.html'
})
export class EditProfileComponent implements OnInit {
    profile: Profile = {
        firstName: '',
        lastName: ''
    };

    constructor(private profileService: ProfileService,
                private toastService: ToastService) {
    }

    ngOnInit(): void {
        this.profileService.getProfile()
            .subscribe(profile => this.profile = profile);
    }

    onSubmit(): void {
        this.profileService.updateProfile(this.profile)
            .subscribe(() => this.toastService.message('Tiedot tallennettu', 'success'));
    }
}
