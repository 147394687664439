import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
    DoCheck
} from '@angular/core';
import { AuthService } from '../../core/service/auth.service';


@Directive({ selector: '[hasRole]' })
export class HasRoleDirective implements DoCheck {
    private hasView = false;
    private roles: string[];

    constructor(private authService: AuthService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef) { }

    @Input() set hasRole(role: string|string[]) {
        this.roles = role instanceof Array ? role : [role];
        this.showOrHideContent();
    }

    ngDoCheck(): void {
        this.showOrHideContent();
    }

    private showOrHideContent(): void {
        this.authService.hasAnyRole(this.roles)
            .subscribe((hasRole) => {
                if (hasRole && !this.hasView) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                    this.hasView = true;
                } else if (!hasRole && this.hasView) {
                    this.viewContainer.clear();
                    this.hasView = false;
                }
            });
    }
}
