import {Component, EventEmitter, Input, Output} from "@angular/core";
import {PageChangedEvent} from "ngx-bootstrap";
import {BaseCriteria, Paged} from "../../../core/model/common";

@Component({
    selector: 'result-pager',
    template: `
        <table *ngIf="page" style="width: 100%">
            <tr>
                <td>
                    <pagination *ngIf="page.totalResultCount > criteria.size"
                                [totalItems]="page.totalResultCount"
                                [itemsPerPage]="criteria.size"
                                [maxSize]="10" [boundaryLinks]="true"
                                [previousText]="'global.pagination.previous' | i18n"
                                [nextText]="'global.pagination.next' | i18n"
                                [firstText]="'global.pagination.first' | i18n"
                                [lastText]="'global.pagination.last' | i18n"
                                [(ngModel)]="criteria.page" (pageChanged)="pageChanged($event)"></pagination>
                </td>
                <td style="text-align: right; vertical-align: baseline">
                    {{'global.perPage' | i18n}}
                    <select [ngModel]="criteria.size" (ngModelChange)="setPageSize($event)">
                        <option *ngFor="let pageSize of pageSizes" [value]="pageSize">{{pageSize}}</option>
                    </select>
                </td>
            </tr>
        </table>
    `
})
export class ResultPagerComponent<T> {
    @Input() page: Paged<T>;
    @Input() criteria: BaseCriteria;
    @Output() change: EventEmitter<number> = new EventEmitter<number>();
    @Input() pageSizes = [5, 10, 20, 50, 100, 500, 1000];

    pageChanged(e:PageChangedEvent) {
        if (!this.page || !this.criteria || e.page==this.criteria.page) {
            return;
        }
        this.criteria.page = e.page;
        this.change.emit(e.page);
    }

    setPageSize(size:number) {
        this.criteria.size = size;
        this.pageChanged({page: 0, itemsPerPage: size})
    }
}