import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {I18nService} from "../../../core/service/i18n.service";

@Component({
    selector: 'language-chooser',
    templateUrl: './language-chooser.component.html',
    styleUrls: ['./language-chooser.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LanguageChooserComponent implements OnInit, OnDestroy {
    locales: {value, label}[] = [];

    constructor(private i18nService: I18nService) {
    }

    ngOnInit(): void {
        this.i18nService.languageChanged.subscribe(() => {
            this.updateAvailableLocales();
        });
    }

    private updateAvailableLocales() {
        this.i18nService.availableLocales.subscribe(locales => {
          this.locales = locales.map(locale => {
              return {
                  label: locale.language.toUpperCase(),
                  value: locale.language
              };
          });
        });
    }

    ngOnDestroy(): void {
    }

    setLocale(locale: any) {
        this.i18nService.lang = locale;
    }
}
