import {Injectable} from '@angular/core';
import {User, UserAuthentication, UserEdit, UserList} from '../model/user';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable()
export class UserService {
    apiUrl = '/api/user';

    constructor(private http: HttpClient) {
    }

    listUsers(): Observable<UserList[]> {
        return this.http.get<UserList[]>(this.apiUrl);
    }

    getUser(id: number): Observable<UserEdit> {
        return this.http.get<UserEdit>(`${this.apiUrl}/${id}`);
    }

    getLoggedInUser(): Observable<UserAuthentication> {
        return this.http.get<UserAuthentication>(`${this.apiUrl}/current`);
    }

    saveUser(user: UserEdit): Observable<UserEdit> {
        if (user.id) {
            return this.updateUser(user);
        }
        return this.createUser(user);
    }

    deleteUser(id: number): Observable<Object> {
        return this.http.delete(`${this.apiUrl}/${id}`);
    }

    private createUser(user: UserEdit): Observable<UserEdit> {
        return this.http.post<User>(this.apiUrl, user);
    }

    private updateUser(user: UserEdit): Observable<UserEdit> {
        return this.http.put<User>(`${this.apiUrl}/${user.id}`, user);
    }
}
