import {NgModule} from '@angular/core';

import {SharedModule} from '../shared/shared.module';
import {AdminComponent} from './admin.component';
import {AdminRoutingModule} from './admin-routing.module';
import {UserModule} from './user/user.module';


@NgModule({
    imports: [
        SharedModule,
        UserModule,
        AdminRoutingModule
    ],
    declarations: [
        AdminComponent
    ]
})
export class AdminModule {
}
