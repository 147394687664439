import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Profile, ChangePassword} from '../model/profile';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class ProfileService {
    apiUrl = '/api/user/profile';

    constructor (private http: HttpClient) { }


    getProfile(): Observable<Profile> {
        return this.http.get<Profile>(this.apiUrl);
    }

    updateProfile(profile: Profile): Observable<Profile> {
        return this.http.put<Profile>(this.apiUrl, profile);
    }

    changePassword(changePw: ChangePassword): Observable<Object> {
        return this.http.put(`${this.apiUrl}/pw`, changePw);
    }
}
