import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ProfileComponent} from './profile.component';
import {AuthGuard} from '../core/guard/auth-guard.service';
import {EditProfileComponent} from './edit-profile.component';
import {ChangePasswordComponent} from './change-password.component';


const profileRoutes: Routes = [
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                canActivateChild: [AuthGuard],
                children: [
                    {path: '', redirectTo: '/profile/edit', pathMatch: 'full'},
                    {path: 'edit', component: EditProfileComponent},
                    {path: 'changepw', component: ChangePasswordComponent}
                ]
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(profileRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class ProfileRoutingModule {
}
