import {Component} from '@angular/core';

@Component({
    template: `
        <side-navi [items]="items">
        </side-navi>
    `
})
export class ProfileComponent {
    items = [
        {
            i18n: 'profile.title',
            route: 'edit'
        },{
            i18n: 'changePassword.title',
            route: 'changepw'
        }
    ];
}
