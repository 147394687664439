import {Component, Input, OnInit} from '@angular/core';
import {UserApplicationAccess, UserEdit} from '../../core/model/user';
import {Router} from '@angular/router';
import {RoleService} from '../../core/service/role.service';
import {UserService} from '../../core/service/user.service';
import {Role} from '../../core/model/role';
import {Application} from "../../core/model/application";
import {ApplicationService} from "../../core/service/application.service";

@Component({
    selector: 'user-form',
    templateUrl: './user-form.component.html'
})
export class UserFormComponent implements OnInit {

    @Input() user: UserEdit;
    @Input() createMode = false;

    roles: Role[] = [];
    applications: Application[] = [];
    newAccessApplicationId: number|string = '';
    newAccessLocaleId: number|string = '';
    newRole: string;

    constructor(private router: Router,
        private userService: UserService,
        private roleService: RoleService,
        private applicationService: ApplicationService) { }

    ngOnInit(): void {
        this.roleService.listRoles()
            .subscribe((roles) => this.roles = roles);
        this.applicationService.list({}).subscribe(applications => {
            this.applications = applications;
        });
    }

    onSubmit(): void {
        this.userService.saveUser(this.user)
            .subscribe(() => this.router.navigate(['/admin/user']));
    }

    generatePassword(attempt:number = 0): void {
        const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let pw = '';
        for (let i = 0; i < 10; i++) {
            const randomPoz = Math.floor(Math.random() * charSet.length);
            pw += charSet.substring(randomPoz, randomPoz + 1);
        }
        this.user.password = pw;
        if (!pw.match(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})/) && attempt < 5) {
            this.generatePassword(attempt+1);
        }
    }

    removeAccess(access: UserApplicationAccess) {
        this.user.accesses.splice(this.user.accesses.indexOf(access), 1);
    }

    localesForSelectedApp() {
        let apps = this.applications.filter(a => a.id == this.newAccessApplicationId);
        if (apps.length == 1) {
            return apps[0].locales;
        }
        return [];
    }

    addAccess() {
        if (this.newAccessApplicationId) {
            let apps = this.applications.filter(a => a.id == this.newAccessApplicationId);
            if (apps.length == 1) {
                let app = apps[0];
                let access:UserApplicationAccess = {
                    applicationId: app.id,
                    applicationName: app.name,
                };
                if (this.newAccessLocaleId) {
                    let locales = app.locales.filter(l => l.id == this.newAccessLocaleId);
                    if (locales.length == 1) {
                        let locale = locales[0];
                        access.localeId = locale.id;
                        access.locale = locale.locale;
                    }
                }
                this.user.accesses.filter(a => a.applicationId == access.applicationId
                    && (!access.localeId || a.localeId == access.localeId))
                    .forEach(a => this.removeAccess(a));
                this.user.accesses.push(access);
                this.newAccessApplicationId = '';
                this.newAccessLocaleId = '';
            }
        }
    }

    localizedRoleName(role: string) {
        if (this.roles) {
            let roles = this.roles.filter(r => r.roleName == role);
            if (roles.length == 1) {
                return roles[0].localizedName;
            }
        }
        return role;
    }

    addRole() {
        if (this.newRole && this.user.roles.indexOf(this.newRole) === -1) {
            this.user.roles.push(this.newRole);
            setTimeout(() => {
                this.newRole = '';
            });
        }
    }

    removeRole(role: string) {
        this.user.roles.splice(this.user.roles.indexOf(role), 1);
    }
}
