import {ApplicationRef, EventEmitter, Injectable, Injector} from '@angular/core';
import {UserService} from './user.service';
import {User, UserEdit} from '../model/user';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {EMPTY, Observable, of as observableOf} from 'rxjs';
import {shareReplay, switchMap, tap} from 'rxjs/operators';


@Injectable()
export class AuthService {
    onLogout: EventEmitter<any> = new EventEmitter<any>();
    loggedInUser: UserEdit;

    // store the URL so we can redirect after logging in
    redirectUrl: string;

    constructor(private http: HttpClient,
                private userService: UserService,
                private injector: Injector) {
    }

    isLoggedIn(): boolean {
        return this.loggedInUser != null;
    }

    getLoggedInUser(): Observable<UserEdit> {
        if (this.loggedInUser != null) {
            return observableOf(this.loggedInUser);
        }
        return this.userService.getLoggedInUser()
            .pipe(shareReplay(1))
            .pipe(tap(auth => this.loggedInUser = auth.authenticatedUser),
                switchMap(auth => auth.authenticated ? observableOf(auth.authenticatedUser) : EMPTY));
    }

    hasAnyRole(roles: string[]): Observable<boolean> {
        return this.hasRole.apply(this, roles);
    }

    hasRole(...roles: string[]): Observable<boolean> {
        if (!this.isLoggedIn()) {
            return observableOf(false);
        }
        return this.getLoggedInUser()
            .pipe(switchMap((user: UserEdit) => {
                if (!(roles instanceof Array)) {
                    roles = [roles];
                }
                return observableOf(user.roles.filter(uRole => roles
                            .filter(r => r == uRole
                                || uRole == `ROLE_${r}`).length > 0).length > 0);
            }));
    }

    login(username: string, password: string): Observable<User> {
        const body = new HttpParams()
            .set('username', username)
            .set('password', password);

        return this.http.post('/api/auth/login',
            body.toString(),
            {
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            }
        ).pipe(switchMap(() => this.getLoggedInUser()));
    }

    logout(): Observable<Object> {
        this.onLogout.emit({});
        return this.http.post('/api/auth/logout', {})
            .pipe(tap(() => this.setLoggedInUser(null)));
    }

    private doApplicationTick(): void {
        this.injector.get(ApplicationRef).tick();
    }

    private setLoggedInUser(user: User): void {
        this.loggedInUser = user;
        this.doApplicationTick();
    }
}
