import {Injectable} from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../service/auth.service';


abstract class RequireRoleGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService, private requiredRole: string) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.hasRole(this.requiredRole);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }
}

@Injectable()
export class UserRoleGuard extends RequireRoleGuard {
    constructor(authService: AuthService) {
        super(authService, 'USER');
    }
}

@Injectable()
export class AdminRoleGuard extends RequireRoleGuard {
    constructor(authService: AuthService) {
        super(authService, 'ADMIN');
    }
}
