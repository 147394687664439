import {Component} from '@angular/core';
import {UserEdit} from '../../core/model/user';

@Component({
    template: `
        <h2>{{'admin.user.addUser' | i18n}}</h2>
        <hr />
        <user-form [user]="user" createMode="true"></user-form>
    `
})
export class UserCreateComponent {
    user: UserEdit = {
        username: '',
        roles: ['ROLE_USER'],
        accesses: []
    };
}
