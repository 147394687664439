import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ApplicationService} from "../../core/service/application.service";
import {I18nService} from "../../core/service/i18n.service";
import {ToastService} from "../../core/service/toast.service";
import {Application} from "../../core/model/application";

@Component({
    selector: 'app-create-application',
    templateUrl: './create-application.component.html'
})
export class CreateApplicationComponent implements OnInit {
    application: Application;

    constructor(private applicationService: ApplicationService,
                private i18nService: I18nService,
                private toastService: ToastService,
                private router: Router) {
    }

    ngOnInit() {
        this.application = {identifier: '', name: '', locales: []};
    }

    save() {
        this.applicationService.create(this.application).subscribe(() => {
            this.toastService.message(this.i18nService.getLocalizedValue('application.create.success',
                this.application.name));
            this.router.navigate(['/applications']);
        });
    }
}
