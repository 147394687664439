import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AdminComponent} from './admin.component';
import {UserListComponent} from './user/user-list.component';
import {UserEditComponent} from './user/user-edit.component';
import {UserCreateComponent} from './user/user-create.component';
import {AdminRoleGuard} from '../core/guard/role-guard.service';


const adminRoutes: Routes = [
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AdminRoleGuard],
        children: [
            {
                path: '',
                canActivateChild: [AdminRoleGuard],
                children: [
                    {path: '', redirectTo: '/admin/user', pathMatch: 'full'},
                    {path: 'user', component: UserListComponent},
                    {path: 'user/create', component: UserCreateComponent},
                    {path: 'user/:id', component: UserEditComponent}
                ]
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(adminRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AdminRoutingModule {
}
