import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {I18nPipe} from "./pipe/i18n.pipe";
import {
    SideNaviComponent,
    SideNaviContentDirective,
    SideNaviItemDirective
} from "./component/side-navi/side-navi.component";
import {FieldErrorDirective} from "./directive/field-error.directive";
import {AnonymousDirective, AuthenticatedDirective} from "./directive/authenticated.directive";
import {HasRoleDirective} from "./directive/role.directive";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {LanguageChooserComponent} from "./component/language-chooser/language-chooser.component";
import {ResultPagerComponent} from "./component/result-pager/result-paginator";
import {PaginationModule} from "ngx-bootstrap";
import {OrderByColumnComponent} from "./component/order-by-column/oder-by-column";
import {HasRolePipe} from "./pipe/has-role.pipe";
import {Select2Module} from "select2-angular-component";

@NgModule({
    declarations: [
        I18nPipe,
        HasRolePipe,

        SideNaviComponent,

        HasRoleDirective,
        AuthenticatedDirective,
        AnonymousDirective,
        FieldErrorDirective,
        SideNaviItemDirective,
        SideNaviContentDirective,
        LanguageChooserComponent,
        ResultPagerComponent,
        OrderByColumnComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        PaginationModule,
        Select2Module
    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,

        I18nPipe,
        HasRolePipe,

        SideNaviComponent,

        HasRoleDirective,
        AuthenticatedDirective,
        AnonymousDirective,
        FieldErrorDirective,
        SideNaviItemDirective,
        SideNaviContentDirective,
        LanguageChooserComponent,
        ResultPagerComponent,
        OrderByColumnComponent
    ]
})
export class SharedModule {
}
