import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Params, Router} from "@angular/router";
import {I18nService} from "../../core/service/i18n.service";
import {ToastService} from "../../core/service/toast.service";
import {LocalizationsService} from "../../core/service/localizations.service";
import {EditValue, LocalisationEditModel} from "../../core/model/localization";
import {ApplicationLocaleList} from "../../core/model/application";
import {ApplicationService} from "../../core/service/application.service";

@Component({
    selector: 'app-edit-localization',
    templateUrl: './edit-localization.component.html',
    styleUrls: ['./edit-localization.component.scss']
})
export class EditLocalizationComponent implements OnInit, OnDestroy {
    localization: LocalisationEditModel;
    locales: ApplicationLocaleList[];
    values: EditValue[];
    private paramsSubscription: any;

    constructor(private route: ActivatedRoute,
                private localizationService: LocalizationsService,
                private applicationService: ApplicationService,
                private i18nService: I18nService,
                private toastService: ToastService,
                private router: Router) {
        this.i18nService.languageChanged.subscribe(() => {
            if (this.localization) {
                this.updateLocales(this.localization);
            }
        })
    }

    ngOnInit(): void {
        this.paramsSubscription = this.route.params.subscribe((params: Params) => {
            this.localizationService.get(+params['id']).subscribe(localization => {
                this.localization = localization;
                this.values = [];
                Object.entries(this.localization.values).forEach(kv => {
                    this.values.push({locale: kv[0], value: kv[1], displayName: kv[0],
                        disabled: true});
                });
                this.updateLocales(localization);
            });
        });
    }

    private updateLocales(localization) {
        this.applicationService.listLocales(localization.applicationIdentifier, {withAccess: true})
            .subscribe(locales => {
                this.locales = locales;
                locales.forEach(l => {
                    let existing = this.values.filter(v => v.locale === l.locale);
                    if (!existing.length) {
                        this.values.push({
                            locale: l.locale, value: null, displayName: l.displayName,
                            disabled: false
                        });
                    } else if (l.displayName) {
                        existing[0].displayName = l.displayName;
                        existing[0].disabled = false;
                    }
                })
            });
    }

    ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }

    save() {
        this.localization.values = {};
        this.values.forEach(l => {
            if (l.value) {
                this.localization.values[l.locale] = l.value;
            }
        });
        this.localizationService.update(this.localization).subscribe(() => {
            this.toastService.message(this.i18nService.getLocalizedValue('localization.update.success',
                this.localization.key));
            this.router.navigate(['/localizations'], this.resolveExtras());
        });
    }

    cancel() {
        this.router.navigate(['/localizations'], this.resolveExtras());
    }

    private resolveExtras() {
        let extra:NavigationExtras = {};
        if (this.localizationService.lastViewedId) {
            extra.fragment =`${this.localizationService.lastViewedId}-${this.localizationService.lastViewedPosition}`;
        }
        return extra;
    }
}
