import {Pipe, PipeTransform} from '@angular/core';
import {I18nService} from "../../core/service/i18n.service";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({ name: 'i18n', pure: false })
export class I18nPipe implements PipeTransform {

    constructor(private i18nService: I18nService,
                private sanitizer: DomSanitizer) {
    }

    transform(key: string | object, ...params: any[]) {
        if (typeof key === 'string' || key instanceof String) {
            let value = this.i18nService.getLocalizedValue(<string>key, ...params);
            if (key.substr(key.length - ".richtext".length, ".richtext".length) == ".richtext") {
                return this.sanitizer.bypassSecurityTrustHtml(value);
            }
            return value;
        } else if (key) {
            if (key[this.i18nService.lang]) {
                return key[this.i18nService.lang];
            }
            if (typeof key[this.i18nService.lang] === 'undefined' || key[this.i18nService.lang] === null) {
                // if undefined or null for current lang, default to fi locale:
                return key['fi'];
            }
            // however, allow empty (new String(""), '') values:
            return key[this.i18nService.lang];
        }
        return "";
    }
}