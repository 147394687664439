import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {CoreModule} from "./core/core.module";
import {I18nService} from "./core/service/i18n.service";
import {SharedModule} from "./shared/shared.module";
import {AdminModule} from "./admin/admin.module";
import {AuthService} from "./core/service/auth.service";
import {zip as observableZip} from 'rxjs';
import {ApplicationsModule} from "./applications/applications.module";
import {ErrorHandlerInterceptor} from "./core/interceptor/error-handler.interceptor";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ProfileModule} from "./profile/profile.module";
import {LocalizationsModule} from "./localizations/localizations.module";
import {PaginationModule} from "ngx-bootstrap";
import {flatMap} from "rxjs/operators";
import {Select2Module} from "select2-angular-component";
import {SortablejsModule} from "angular-sortablejs";

var queryDict: any = {};
location.search.substr(1).split("&").forEach(function (item) {
    queryDict[item.split("=")[0]] = item.split("=")[1]
});

const doInit = function (authService: AuthService, i18nService: I18nService) {
    return function () {
        return observableZip(
            authService.getLoggedInUser().toPromise(),
            i18nService.getUserLocale().pipe(flatMap(locale => {
                let queryLang = queryDict.lang || queryDict.locale;
                if (queryLang && i18nService.lang != queryLang) {
                    return i18nService.changeLanguage(queryLang).toPromise();
                }
                return i18nService.load(queryLang || locale || 'fi').toPromise()
            }))
        ).toPromise();
    };
};

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ProfileModule,
        SharedModule,
        SortablejsModule.forRoot({ animation: 150 }),
        AdminModule,
        CoreModule,
        LocalizationsModule,
        PaginationModule.forRoot(),
        ApplicationsModule,
        Select2Module
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        {
            deps: [AuthService, I18nService],
            multi: true,
            provide: APP_INITIALIZER,
            useFactory: doInit
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
