import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {UserListComponent} from './user-list.component';
import {UserFormComponent} from './user-form.component';
import {UserEditComponent} from './user-edit.component';
import {UserCreateComponent} from './user-create.component';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        SharedModule,
        RouterModule
    ],
    declarations: [
        UserListComponent,
        UserFormComponent,
        UserEditComponent,
        UserCreateComponent
    ]
})
export class UserModule {}
