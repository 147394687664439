import {Component, Input, OnInit} from '@angular/core';
import {Application, ApplicationLocale} from "../../core/model/application";
import {LocalizationsService} from "../../core/service/localizations.service";
import {Locale} from "../../core/model/localization";

@Component({
    selector: 'application-form',
    templateUrl: './application-form.component.html',
    styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit {
    @Input() application: Application;
    locales: Locale[];
    addingNewLocale: boolean = false;
    localeToAdd: string = '';

    constructor(private localizationService: LocalizationsService) {
    }

    ngOnInit() {
        this.localizationService.listLocales().subscribe(locales => this.locales = locales);
    }

    addLocale() {
        if (this.localeToAdd === 'new') {
            this.localeToAdd = '';
            this.addingNewLocale = true;
        } else {
            this.addNewLocale();
        }
    }

    addNewLocale() {
        if (this.localeToAdd && this.application.locales.filter(l => l.locale === this.localeToAdd).length === 0) {
            this.application.locales.push({locale: this.localeToAdd, supported: false});
            this.addingNewLocale = false;
            setTimeout(() => {
                this.localeToAdd = '';
            });
        }
    }

    availableLocales() {
        if (!this.locales) {
            return [];
        }
        return this.locales.filter(l => this.application.locales.filter(al => al.locale === l.locale).length === 0);
    }

    cancelAddNewLocale() {
        this.localeToAdd = '';
        this.addingNewLocale = false;
    }

    removeLocale(l: ApplicationLocale) {
        this.application.locales.splice(this.application.locales.indexOf(l), 1);
    }
}
