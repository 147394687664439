import {Injectable} from "@angular/core";

import * as jQuery from 'jquery';
export type PanelTheme = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

@Injectable()
export class ToastService {

    message(text: string, theme: PanelTheme = 'primary') {
        const $toast = jQuery('<div role="alert" class="alert alert-' + theme + '">' + text + '</div>');
        $toast.on('click', () => $toast.remove());
        setTimeout(() => $toast.remove(), 3000);
        $toast.appendTo('#toasts');
    }
}