import {Component, OnDestroy, OnInit} from '@angular/core';
import {LocalizationsService} from "../../core/service/localizations.service";
import {Localization, LocalizationCriteria} from "../../core/model/localization";
import {Paged} from "../../core/model/common";
import {ApplicationService} from "../../core/service/application.service";
import {Application} from "../../core/model/application";
import {ActivatedRoute, Router} from "@angular/router";
import {I18nService} from "../../core/service/i18n.service";
import {ToastService} from "../../core/service/toast.service";
import {Subscription} from "rxjs";

export interface BundleSelection {
    id: number
    applicationId: number
    applicationName: string
    bundleName: string
}

export interface Locale {
    locale: string,
    displayName?: string
}

@Component({
    selector: 'app-list-localizations',
    templateUrl: './list-localizations.component.html',
    styleUrls: ['./list-localizations.component.scss']
})
export class ListLocalizationsComponent implements OnInit, OnDestroy {
    criteria: LocalizationCriteria;
    page: Paged<Localization>;
    applications: Application[];
    bundles: BundleSelection[];
    locales: Locale[];
    showKeys: boolean = false;
    lastViewedPosition: number;
    lastViewedId: number;
    languageChangeSubscription: Subscription;

    constructor(private localizationService: LocalizationsService,
                private applicationService: ApplicationService,
                private router: Router,
                private route: ActivatedRoute,
                private i18nService: I18nService,
                private toastService: ToastService) {
    }

    ngOnInit() {
        this.languageChangeSubscription = this.i18nService.languageChanged.subscribe(lang => {
            if (lang) {
                this.search();
                this.updateApplications();
            }
        });
        this.lastViewedId = this.localizationService.lastViewedId;
        this.lastViewedPosition = this.localizationService.lastViewedPosition;
        this.updateApplications();
        this.criteria = this.localizationService.criteria || {
            size: 10,
            page: 1,
            applicationId: '',
            bundleId: '',
            applicationHidden: false,
            includeLinks: false,
            includeShortValues: false,
            orderBy: ['applicationName', 'bundleName', 'lowerKey']
        };
        this.showKeys = this.localizationService.showKeys;
        this.search();
        this.scrollToFragment();
    }

    ngOnDestroy(): void {
        if (this.languageChangeSubscription) {
            this.languageChangeSubscription.unsubscribe();
        }
    }

    private updateApplications() {
        this.applicationService.list({
                select: 'id, name, previewUrl, identifier, bundles, locales', orderBy: 'name',
                hidden: false
            }).subscribe(applications => {
                this.applications = applications;
                this.bundles = [];
                this.applications.forEach(app => {
                    app.bundles.forEach(b => {
                        this.bundles.push({
                            id: b.id,
                            applicationId: app.id,
                            applicationName: app.name,
                            bundleName: b.name,
                        });
                    });
                });
                this.updateAvailableLocales();
            });
    }

    private updateAvailableLocales() {
        if (this.applications) {
            this.locales = [];
            this.applications.forEach(app => {
                if ((!this.criteria.applicationId || this.criteria.applicationId == app.id)
                        && (!this.criteria.bundleId || app.bundles.filter(b => b.id == this.criteria.bundleId).length)) {
                    app.locales.map(l => ({locale: l.locale, displayName: l.displayName}))
                        .filter(l => !this.locales.filter(cl => cl.locale == l.locale).length)
                        .forEach(l => this.locales.push(l));
                }
            });
            this.locales.sort();
        }
    }

    private scrollToFragment() {
        this.route.fragment.subscribe(f => {
            if (f) {
                let prts = f.split('-');
                const element = document.querySelector("#localization-" + prts[0]);
                if (element) {
                    element.scrollIntoView();
                } else {
                    const posEl = document.querySelector("#row-with-position-" + prts[1]);
                    if (posEl) {
                        posEl.scrollIntoView();
                    }
                }
            }
        });
    }

    search() {
        if (!this.criteria) {
            return;
        }
        if (this.criteria.bundleId && this.criteria.applicationId && this.bundles) {
            let bResult = this.bundles.filter(b => b.id == this.criteria.bundleId);
            if (bResult.length && bResult[0].applicationId != this.criteria.applicationId) {
                this.criteria.applicationId = '';
            }
        }
        this.localizationService.criteria = this.criteria;
        this.localizationService.showKeys = this.showKeys;
        this.localizationService.page(this.criteria, true).subscribe(page => {
            this.page = page;
            setTimeout(() => {
                this.scrollToFragment();
            });
            this.updateAvailableLocales();
        });
    }

    editLocalization(id: number, i:number) {
        this.localizationService.lastViewedId = id;
        this.localizationService.lastViewedPosition = this.orderNumber(i);
        this.router.navigate(['/localizations/edit', id]);
    }

    orderNumber(i: number) {
        return ((this.criteria.page || 1)-1) * this.criteria.size + i + 1;
    }

    localizationReady() {
        if (!this.criteria.notLocalizedIn || this.criteria.notLocalizedIn instanceof Array
                || (!this.criteria.applicationId && this.applications.length != 1)) {
            return;
        }
        if (confirm(this.i18nService.getLocalizedValue('localizations.forLanguage.ready.notify.confirm', this.criteria.notLocalizedIn))) {
            this.applicationService.languageReadyForApproval((<number>this.criteria.applicationId || this.applications[0].id),
                    <string>this.criteria.notLocalizedIn)
                .subscribe(() => this.toastService.message(
                    this.i18nService.getLocalizedValue('localizations.forLanguage.ready.confirmed',
                        this.criteria.notLocalizedIn), 'info'))
        }
    }

    protected resolvePreviewUrl(): string {
        if (!this.criteria.notLocalizedIn || this.criteria.notLocalizedIn instanceof Array
                || (!this.criteria.bundleId && !this.criteria.applicationId && this.applications.length != 1)) {
            return null;
        }
        let applicationId = null;
        if (this.criteria.bundleId) {
            let bundle = this.bundles.filter(b => b.id == this.criteria.bundleId)[0];
            applicationId = bundle.applicationId;
        } else {
           applicationId = <number>this.criteria.applicationId || this.applications[0].id;
        }
        let application = this.applications.filter(a => a.id == applicationId)[0];
        if (!application.previewUrl) {
            return null;
        }
        let locale = <string>this.criteria.notLocalizedIn;
        return application.previewUrl.replace('{0}', locale);
    }

    isPreviewAvailable() {
        return this.resolvePreviewUrl() != null;
    }

    previewSelectedLocale($event) {
        $event.preventDefault();
        let url = this.resolvePreviewUrl();
        if (url) {
            window.open(url, '_blank');
        }
        return false;
    }
}
