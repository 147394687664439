import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'localizations',
    template: `<router-outlet></router-outlet>`
})
export class LocalizationsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
