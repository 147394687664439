import {Component, OnDestroy, OnInit} from '@angular/core';
import {User, UserEdit} from '../../core/model/user';
import {UserService} from '../../core/service/user.service';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
    template: `
        <h2>{{'user.edit' | i18n}}</h2>
        <p *ngIf="!user">{{'global.loading' | i18n}}</p>
        <user-form *ngIf="user" [user]="user"></user-form>
    `
})
export class UserEditComponent implements OnInit, OnDestroy {
    isLoading = true;
    user: UserEdit;
    private paramsSubscription: any;

    constructor(private route: ActivatedRoute,
        private userService: UserService) {
    }

    ngOnInit(): void {
        this.paramsSubscription = this.route.params.subscribe((params: Params) => {
            this.userService.getUser(+params['id']).subscribe((user: User) => {
                this.user = user;
                this.isLoading = false;
            });
        });
    }

    ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }
}
