import {Injectable, isDevMode} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of} from "rxjs";
import "rxjs/add/operator/do";
import {ApplicationLocaleList} from "../model/application";
import {flatMap} from "rxjs/operators";

declare var require: any;

export interface I18n {
    [key: string]: string;
}

interface Messages {
    [key:string]: string
}
interface MessagesCache {
    [key:string]: Messages
}

@Injectable()
export class I18nService {
    languageChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _lang: string = 'fi';
    private _firstInitStarted: boolean;
    private _langMessagesCache:MessagesCache = {};
    private messages: Messages;

    constructor(private http: HttpClient) {
    }

    public load(lang: string): Observable<Messages> {
        let firstLoad = !this._firstInitStarted;
        let langWas = this._lang;
        this._lang = lang;
        if (langWas !== lang || !this.messages) {
            if (isDevMode()) {
                console.log('Load messages ', lang, 'first load', firstLoad);
            }
            if (firstLoad) {
                this._firstInitStarted = true;
            }
            return this.http.get<Messages>(`/messages/${lang}.json`)
                .do(messages => {
                    this.messages = messages;
                    this._langMessagesCache[lang] = messages;
                    if (!firstLoad) {
                        this.languageChanged.next(lang);
                    }
                }, e => {
                    console.error('error loading messages for lang ', lang, e)
                });
        }
        return of(this.messages);
    }

    getMessages(lang: string): Messages {
        if (this._langMessagesCache[lang]) {
            return this._langMessagesCache[lang];
        }
        this.http.get<Messages>(`/messages/${lang}.json`)
            .do(messages => {
                this._langMessagesCache[lang] = messages;
            }, e => {
                console.error('error loading messages for lang ', lang, e)
            }).subscribe(msg => {});
        return {};
    }

    get lang(): string {
        return this._lang;
    }

    set lang(lang: string) {
        this.changeLanguage(lang).subscribe(() => {});
    }

    changeLanguage(lang: string): Observable<Messages> {
        return this.http.put<string>(`/api/user/locale/${lang}`, null)
            .pipe(flatMap(() => this.load(lang)));
    }

    localize(obj: I18n) {
        if (!obj) {
            return "";
        }
        if (obj[this.lang]) {
            return obj[this.lang];
        }
        return obj['fi'];
    }

    getLocalizedValueByLang(lang:string, key: string, ...params: any[]): string {
        let messages = this.getMessages(lang);
        if (!messages) {
            return `[[${key}]]`;
        }
        let msg: string = messages[key];
        if (!msg && !(msg === '')) {
            return `[[${key}]]`;
        }
        params.forEach((value, index) => msg = msg.replace('{' + index + '}', value));
        return msg;
    }

    getLocalizedValue(key: string, ...params: any[]): string {
        if (!this.messages) {
            return `[[${key}]]`;
        }

        let msg: string = this.messages[key];
        if (!msg && !(msg === '')) {
            return `[[${key}]]`;
        }
        params.forEach((value, index) => msg = msg.replace('{' + index + '}', value));

        return msg;
    }

    isLocalized(key: string) {
        if (!this.messages) {
            return false;
        }
        return !!this.messages[key];
    }

    isLocalizedByLang(lang: string, key: string) {
        let messages = this.getMessages(lang);
        if (!messages) {
            return false;
        }
        return !!messages[key];
    }

    get availableLocales(): Observable<ApplicationLocaleList[]> {
        return this.http.get<ApplicationLocaleList[]>('/api/application/localization.x/locale?supported=true');
    }

    getUserLocale(): Observable<string> {
        return this.http.get<string>('/api/user/locale');
    }
}
