import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {ApplicationsComponent} from "./applications.component";
import {AdminRoleGuard} from "../core/guard/role-guard.service";
import {CreateApplicationComponent} from "./create-application/create-application.component";
import {EditApplicationComponent} from "./edit-application/edit-application.component";
import {ApplicationListComponent} from "./application-list/application-list.component";

const routes: Routes = [
    {
        path: 'applications',
        component: ApplicationsComponent,
        canActivate: [AdminRoleGuard],
        children: [
            {
                path: '',
                canActivateChild: [AdminRoleGuard],
                children: [
                    {path: '', component: ApplicationListComponent, pathMatch: 'full'},
                    {path: 'create', component: CreateApplicationComponent},
                    {path: 'edit/:identifier', component: EditApplicationComponent}
                ]
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ApplicationsRoutingModule {
}
