import {Component, OnInit} from '@angular/core';
import {Application, ApplicationLocale} from "../../core/model/application";
import {ApplicationService} from "../../core/service/application.service";
import {I18nService} from "../../core/service/i18n.service";
import {ToastService} from "../../core/service/toast.service";

@Component({
    selector: 'app-application-list',
    templateUrl: './application-list.component.html',
    styleUrls: ['./application-list.component.scss']
})
export class ApplicationListComponent implements OnInit {
    applications: Application[];

    constructor(private applicationService: ApplicationService,
                private i18nService: I18nService,
                private toastService: ToastService) {
    }

    ngOnInit() {
        this.updateList();
    }

    private updateList() {
        this.applicationService.list({orderBy: 'identifier'})
            .subscribe(applications => this.applications = applications);
    }

    deleteApplication(application: Application) {
        if (confirm(this.i18nService.getLocalizedValue('application.delete.confirm', application.name))) {
            this.applicationService.delete(application.id).subscribe(() => {
                this.toastService.message(this.i18nService.getLocalizedValue('application.deleted.success',
                    application.name));
                this.updateList();
            });
        }
    }

    preview($event, application: Application, locale: ApplicationLocale) {
        $event.preventDefault();
        $event.stopPropagation();
        if (application.previewUrl) {
            window.open(application.previewUrl.replace('{0}', locale.locale), '_blank');
        }
        return false;
    }
}
